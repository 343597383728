import { css, styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

export const HeroBackground = styled('div')`
  margin-bottom: -2px;
`;

export const HeroContainer = styled(Container)(
  ({ theme }) => css`
    padding-bottom: 3.75rem;
    padding-top: 1.5625rem;

    ${theme.breakpoints.down('sm')} {
      display: flex;
      justify-content: center;
    }
  `
);

export const LogoContainer = styled('div')`
  margin-bottom: 3.75rem;
`;

export const HeroDetails = styled('div')(
  ({ theme }) => css`
    margin-bottom: 3.75rem;

    ${theme.breakpoints.down('sm')} {
      max-width: 90%;
      text-align: center;
    }
  `
);

export const Intro = styled(Typography)(
  ({ theme }) => css`
    color: #fee2e2;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 1.25rem;
    margin-bottom: 0.625rem;
    text-transform: uppercase;
    width: 100%;

    ${theme.breakpoints.down('sm')} {
      margin-bottom: 0.25rem;
    }
  `
);

export const Header = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    font-size: 3.375rem;
    letter-spacing: -0.44px;
    line-height: 3.75rem;
    margin-bottom: 0.75rem;
    max-width: 42.375rem;

    ${theme.breakpoints.down('sm')} {
      font-size: 2.1825rem;
      line-height: 2.9375rem;
      text-align: center;
    }
  `
);

export const Body = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    font-size: 1.25rem;
    letter-spacing: -0.18px;
    line-height: 2rem;
    margin-bottom: 2rem;
    max-width: 38.25rem;

    ${theme.breakpoints.down('sm')} {
      line-height: 1.8125rem;
    }
  `
);

export const Form = styled('form')<{ $inModal?: boolean }>(
  ({ theme, $inModal }) => css`
    background-color: ${theme.palette.common.white};
    border-radius: 1.125rem;
    height: fit-content;
    max-width: 26.25rem;
    padding: 2rem 2.5rem;
    width: 100%;

    .MuiFormControl-root {
      margin-bottom: 1.375rem;

      .Mui-error {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${theme.colors.redError};
        }
      }
    }

    .MuiInputLabel-root {
      &.Mui-error {
        color: ${theme.colors.redError};
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.5);
    }

    .Mui-error {
      &.MuiInputLabel-asterisk {
        color: ${theme.colors.redError};
      }
    }

    .MuiTextField-root {
      margin-bottom: 1.375rem;
      width: 100%;
    }

    ${theme.breakpoints.down('sm')} {
      margin-top: ${$inModal ? '0' : '-3rem'};
    }
  `
);

export const FormHeader = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.navyGray};
    margin-bottom: 1.5625rem;
    text-align: center;
  `
);

export const FormSelect = styled(Select)`
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
`;

export const ButtonContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const SubmitButton = styled(Button)`
  font-weight: 700;
  margin-bottom: 0.5rem;
  width: fit-content;

  &&& {
    background-color: ${({ theme }) => theme.colors.darkOrange};

    &&& {
      background: ${({ theme }) => theme.colors.orange};
      position: relative;
      overflow: hidden;
    }

    &&&:hover {
      background: ${({ theme }) => theme.colors.orange};
    }

    &&&::before {
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.colors.darkOrange};
      width: 10%;
      height: 25%;
      border-radius: 50%;
      z-index: 1;
      top: 35%;
      left: 45%;
      -webkit-transform: scale(0);
      transform: scale(0);
      transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s, -webkit-transform 0.5s;
    }

    &&&:hover::before {
      -webkit-transform: scale(12);
      transform: scale(12);
      -webkit-transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s, -webkit-transform 0.5s;
    }

    & > span {
      z-index: 50;
      padding: 0;
    }
  }
`;

export const FormAlert = styled(Alert)<{ severity: string }>`
  border: ${({ severity }) => (severity === 'success' ? '1px solid #059669' : '1px solid #cc0000')};
  margin-top: 0.5rem;

  .MuiSvgIcon-root {
    color: ${({ severity }) => (severity === 'success' ? '#059669' : '#cc0000')};
  }
`;

export const Footnote = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  letter-spacing: 0.4px;
  line-height: 1.5rem;
`;

export const DetailsGrid = styled(Grid)(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: flex;
      justify-content: center;
    }
  `
);

export const StyledHeader = styled(Header)(
  ({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      font-size: 2.5rem;
    }
  `
);

export const FormGrid = styled(Grid)(
  ({ theme }) => css`
    height: 100%;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
);

export const DesktopIconBlock = styled('div')<{ $withForm: boolean }>`
  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: ${({ $withForm }) => ($withForm ? 'none' : 'block')};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
  }
`;
