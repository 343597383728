import { gql, MutationFunction, MutationResult, OperationVariables, useMutation } from '@apollo/client';

const CONSULTATION_MAILCHIMP = gql`
  mutation ConsultationMailchimp($input: ConsultationMailchimpInput!) {
    consultationMailchimp(input: $input) {
      success
    }
  }
`;

export interface ConsultationMailchimpResults {
  consultationMailchimp: {
    success: boolean;
  };
}

export interface ConsultationMailchimpMutationResults {
  mutationResults: MutationResult<ConsultationMailchimpResults>;
  consultationMailchimp: MutationFunction<ConsultationMailchimpResults, OperationVariables>;
}

export default function useMutationConsultationMailchimp(): ConsultationMailchimpMutationResults {
  const [consultationMailchimp, { ...result }] = useMutation(CONSULTATION_MAILCHIMP);

  return {
    mutationResults: { ...result },
    consultationMailchimp,
  };
}
