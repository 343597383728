import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Fade from 'react-reveal/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';
import SEO from 'components/SEO/SEO';
import { ResponsiveText22, ResponsiveText46, ResponsiveText74 } from 'components/shared';
import ResponsiveText20 from 'components/shared/atoms/ResponsiveText20/ResponsiveText20';
import ResponsiveText24 from 'components/shared/atoms/ResponsiveText24/ResponsiveText24';
import MailchimpForm from 'components/shared/molecules/MailchimpForm/MailchimpForm';
import background from 'images/home/fan-background.png';
import img2 from 'images/home/home2.png';
import img3 from 'images/home/home3.png';
import img4 from 'images/home/fan-trade-mockups.png';
import { Helmet } from 'react-helmet';

const PageWrapper = styled('div')`
  background: url(${background}) no-repeat top;
  background-size: cover;
  color: white;
  padding: 9rem 5.125rem 5.125rem 5.125rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 7rem 3.375rem 3.375rem 3.375rem;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 7rem 1.5rem 1.5rem 1.5rem;
  }
`;

const CustomGrid = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5.5rem;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const Img = styled('img')`
  max-width: 90%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
    margin-bottom: 3rem;
  }
`;

const FormGrid = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75rem;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down(700)} {
    flex-direction: column;
  }
`;

const FormWrapper = styled('div')`
  max-width: 45%;

  ${({ theme }) => theme.breakpoints.down(700)} {
    max-width: 100%;
  }
`;

const SentenceHeader = styled(ResponsiveText46)`
  margin-bottom: -2.5rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: -1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: -1.375rem;
  }
`;

type SectionContentProps = {
  title: string;
  text: string;
  largeTitle?: boolean;
  largeText?: boolean;
  smallText?: boolean;
};

const sectionText = [
  {
    title: `Watch Live Shows, Collect Live Moments.`,
    text: 'Build a portfolio of alternative assets while you discover and support musicians, comedians, speakers and more all in one place.',
    largeTitle: true,
    largeText: true,
  },
  {
    title: 'Stream',
    text: 'unique live performances on your devices wherever you are in the world.',
    largeText: true,
  },
  {
    title: 'Collect',
    text: 'one-of-a-kind moments from livestream shows to build your portfolio.',
  },
  {
    title: 'Buy & Sell',
    text: 'valuable digital collectibles on our marketplace.',
  },
];

const SectionContent = ({ title, text, largeTitle, largeText, smallText }: SectionContentProps) => {
  return (
    <FormWrapper>
      {largeTitle ? <ResponsiveText74>{title}</ResponsiveText74> : <SentenceHeader>{title}</SentenceHeader>}
      {largeText ? (
        <ResponsiveText24>{text}</ResponsiveText24>
      ) : smallText ? (
        <ResponsiveText20>{text}</ResponsiveText20>
      ) : (
        <ResponsiveText22>{text}</ResponsiveText22>
      )}
    </FormWrapper>
  );
};

const HomeLoggedOut = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Helmet>
        <link rel='canonical' href={`${process.env.GATSBY_SITE_URL}/fan/`} />
      </Helmet>
      <SEO title='Stages For Livestream Performances' />
      <PageWrapper>
        <Container>
          <FormGrid>
            <SectionContent {...sectionText[0]} />
            <FormWrapper>
              <Fade right>
                <MailchimpForm
                  header='Join The Waitlist To Be One Of The First On Live Bash'
                  button='Crash The Party!'
                />
              </Fade>
            </FormWrapper>
          </FormGrid>

          <CustomGrid>
            <Fade left>
              <Img src={img3} alt={'Laptop display of concert livestream'} />
            </Fade>
            <SectionContent {...sectionText[1]} />
          </CustomGrid>

          <CustomGrid>
            {isSmallScreen ? (
              <>
                <Fade right>
                  <Img src={img2} alt={'Blue check mark'} />
                </Fade>
                <SectionContent {...sectionText[2]} />
              </>
            ) : (
              <>
                <SectionContent {...sectionText[2]} />
                <Fade right>
                  <Img src={img2} alt={'Blue check mark'} />
                </Fade>
              </>
            )}
          </CustomGrid>

          <CustomGrid>
            <Fade left>
              <Img src={img4} alt={'Two tablets displaying concert livestream'} />
            </Fade>
            <SectionContent {...sectionText[3]} />
          </CustomGrid>
        </Container>
      </PageWrapper>
    </>
  );
};

export default HomeLoggedOut;
