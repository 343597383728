import React from 'react';
import { PerformerLoggedOutHomeLoggedIn } from 'components/shared';
import { MARKETPLACE_URL } from 'utils/constants';

const HomeLoggedIn = () => {
  const imageHeaderProps = {
    headerText: 'LIVEBASH',
    mainText: 'Calling All Performers: The Stage Is Yours!',
    subText: `We're Offering Stages for Livestream Performances`,
    buttonText: 'Book Now!',
    buttonProps: { to: '/book' },
  };

  const infoSectionProps = {
    title: 'HOW IT WORKS',
    mainContent: 'Live Bash Wants You To Perform',
    subContent: 'Live Bash wants you to succeed on stage!',
    buttonText: 'Experience Live Bash',
    buttonProps: { href: MARKETPLACE_URL },
  };

  return (
    <PerformerLoggedOutHomeLoggedIn
      title={'Stages For Livestream Performances'}
      searchSubText={'BOOK YOUR FIRST SHOW TODAY!'}
      imageHeaderProps={imageHeaderProps}
      infoSectionProps={infoSectionProps}
    />
  );
};

export default HomeLoggedIn;
