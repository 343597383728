import * as React from 'react';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { scrollToTopOfMainSection } from 'utils/scrollUtils';
import HomeLoggedIn from 'components/HomePage/HomeLoggedIn';
import HomeLoggedOut from 'components/HomePage/HomeLoggedOut';
import MainContent from 'components/MainContent/MainContent';

const FanPage = () => {
  const {
    state: { authenticated },
  } = useAuthContext();

  React.useEffect(() => {
    scrollToTopOfMainSection();
  }, []);

  if (!authenticated) {
    return (
      <MainContent>
        <HomeLoggedOut />
      </MainContent>
    );
  }

  return (
    <MainContent>
      <HomeLoggedIn />
    </MainContent>
  );
};

export default FanPage;
